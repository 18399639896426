import React from 'react';
import { CssBaseline, Container } from '@mui/material';
import ScoreGroup from './ScoreGroup';
import { Helmet } from 'react-helmet';
function Scores() {
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
    <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Top Scores, Assists, and Goalkeeper Saves - Liga SJKC Kuala Muda</title>
        <meta name="description" content="Explore the top goal scorers, assist leaders, and goalkeeper saves statistics. Stay updated with the latest performance stats in the league." />
        <meta property="og:title" content="Top Scores, Assists, and Goalkeeper Saves - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Explore the top goal scorers, assist leaders, and goalkeeper saves statistics. Stay updated with the latest performance stats in the league." />
        <meta property="og:url" content="https://www.ligasjkc.com/scores" />
        <meta name="keywords" content="soccer scores, top goal scorers, assists, goalkeeper saves, soccer stats, football statistics" />
      </Helmet>
      <CssBaseline />
      <Container>
        <ScoreGroup />
      </Container>
    </>
  );
}

export default Scores;
