import React from 'react';
import {   CssBaseline, Container } from '@mui/material';
import MatchWeek from './MatchWeek';
import { Helmet } from 'react-helmet';

const Matches = () => {
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  const handleDateRangeClick = (start, end) => {
    // Replace this with actual handling logic
    //console.log(`Selected date range: ${start} - ${end}`);
  };
  return (
    <>
        <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Matches - Liga SJKC Kuala Muda</title>
        <meta name="description" content="Check out the latest matches and schedules." />
        <meta property="og:title" content="Matches - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Check out the latest matches and schedules." />
        <meta property="og:url" content="https://www.ligasjkc.com/matches" />
      </Helmet>
      <CssBaseline />
      <Container> 
      {/* <Typography variant="h4" component="h2" gutterBottom>
        Football Match Results
      </Typography> 
        <MatchResult />*/}
        <MatchWeek
        dateStart="2024-08-10"
        dateEnd="2024-10-20"
        handleDateRangeClick={handleDateRangeClick}
      />
       </Container>
    </>
  );
};

export default Matches;
