import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation,Navigate  } from 'react-router-dom';
import { AppBar, Toolbar, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';

import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import Home from './components/Home';
import Matches from './components/Matches';
import Scores from './components/Scores';
import Teams from './components/Teams';
import MatchDetail from './components/MatchDetail';
import TeamTab from './components/TeamTab';
import Tables from './components/Tables';
import logo from './assets/sjkc.png';
import MatchTab from './components/MatchTab';
import useBackButtonHandler from './useBackButtonHandler';
import { Helmet } from 'react-helmet';
import TableViewIcon from '@mui/icons-material/TableView';
import TableHolder from './components/TableHolder';
import ScoreTab from './components/ScoreTab';
import TeamHolder from './components/TeamHolder';
import Footer from './components/Footer';
import { useNavigate  } from 'react-router-dom';
import InstallApp from './components/InstallApp';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-GGF0DB06BB');

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

/* function useRemoveTrailingSlash() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1) + location.search, { replace: true });
    }
  }, [location, navigate]);
} */

function App() {
  /* const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event so it can be triggered later
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    const triggerInstallPrompt = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          setDeferredPrompt(null); // Clear the deferredPrompt variable
        });
      }
    };

    // Trigger the prompt when the user interacts with the page (e.g., clicks or scrolls)
    const userGestureHandler = () => {
      triggerInstallPrompt();
      window.removeEventListener('scroll', userGestureHandler); // Remove listener after triggering
      window.removeEventListener('click', userGestureHandler);
    };

    // Listen for a scroll or click event as a user gesture
    window.addEventListener('scroll', userGestureHandler);
    window.addEventListener('click', userGestureHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('scroll', userGestureHandler);
      window.removeEventListener('click', userGestureHandler);
    };
  }, [deferredPrompt]); */
  
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    // Check if the app is already installed
    const checkInstalled = window.matchMedia('(display-mode: standalone)').matches;
    setIsInstalled(checkInstalled);

    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  }; 

  return (
 

    <Router>
       <Main onInstallClick={isInstalled ? null : handleInstallClick} /> 
    </Router>
 
  );
}

function Main({ onInstallClick }) {
  const location = useLocation(); 
  const [value, setValue] = useState(location.pathname); 
  const navigate = useNavigate();

  // Function to handle redirection
  const handleRedirect = () => {
    navigate('/installapp'); // Replace '/new-route' with the path to your desired component
  };

  /* useRemoveTrailingSlash(); */ // Remove route/
  usePageTracking(); // Track page views here
  useBackButtonHandler(); // Tap twice in second back to Home for exit

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const getTitle = () => {
    switch (value) {
      case '/tables':
        return 'Tables';
      case '/matches':
        return 'Matches';
      case '/scores':
        return 'Scores'; 
      case '/teams':
        return 'Teams';
      default:
        return 'Liga SJKC K. Muda 2024';
    }
  };

  const hideBottomNavigation = location.pathname.includes('/installapp') || location.pathname.includes('/tables/') || location.pathname.includes('/matches/') || location.pathname.includes('/scores/') || location.pathname.includes('/teams/');
  const showCustomAppBar = location.pathname.includes('/installapp') || location.pathname.includes('/tables/') || location.pathname.includes('/matches/') || location.pathname.includes('/scores/') || location.pathname.includes('/teams/');

  return (
    <>
{/*        <Helmet>
        <title>Liga SJKC Kuala Muda</title>
        <meta name="description" content="Welcome to Liga SJKC  App." />
        <meta property="og:title" content="Liga SJKC App" />
        <meta property="og:description" content="Welcome to Liga SJKC App." />
      </Helmet>  */}
      {!showCustomAppBar && (
        <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
          <Toolbar>
            {/* {hideBottomNavigation && (
              <IconButton edge="start" color="inherit" aria-label="back" component={Link} to={location.pathname.includes('/matches/') ? "/matches" : "/teams"}>
                <ArrowBackIcon />
              </IconButton>
            )} */}
            <Typography variant="h6" component="div">
              {getTitle()}
            </Typography>
             {onInstallClick && (
              <button onClick={handleRedirect} style={{ marginLeft: 'auto' }}>Install App</button>
            )}  
          </Toolbar>
        </AppBar>
      )}


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tables" element={<Tables />} />
        <Route path="/matches" element={<Matches />} />
        <Route path="/scores" element={<Scores />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/installapp" element={<InstallApp />} /> 
        <Route path="/matches/:date1/:date2/:week" element={<MatchDetail />} />
        <Route path="/matches/:id/:team1/:team2/:start/:end/:week" element={<MatchTab />} />
        <Route path="/teams/:gname/:gid" element={<TeamHolder />} /> 
        <Route path="/teams/:gname/:name/:gid/:id" element={<TeamTab />} /> 
        <Route path="/tables/:name/:group" element={<TableHolder />} /> 
        <Route path="/scores/:name/:group" element={<ScoreTab />} /> 
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
     {/* Footer Component */}
      {/* <Footer />  */}
      {!hideBottomNavigation && (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          showLabels
          style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        >
          <BottomNavigationAction
            label="Home"
            icon={
              <img src={logo} alt="Logo" style={{ width: '40px', marginRight: '10px' }} />}
            component={Link}
            to="/"
            value="/"
          />
          <BottomNavigationAction
            label="Tables"
            icon={<TableViewIcon />}
            component={Link}
            to="/tables"
            value="/tables"
          />
          <BottomNavigationAction
            label="Matches"
            icon={<EmojiEventsIcon />}
            component={Link}
            to="/matches"
            value="/matches"
          />
          <BottomNavigationAction
            label="Scores"
            icon={<SportsSoccerIcon />}
            component={Link}
            to="/scores"
            value="/scores"
          />
          <BottomNavigationAction
            label="Teams"
            icon={<GroupIcon />}
            component={Link}
            to="/teams"
            value="/teams"
          />
        </BottomNavigation>
      )}
    
    </>
  );
}

export default App;
