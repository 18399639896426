import React from 'react';
import { IconButton,CssBaseline, AppBar, Toolbar, Container, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';

import { Link } from 'react-router-dom'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Import images
import chromeInstall from '../assets/android-chrome.png'; // Replace with your image path
import safariInstall from '../assets/ios-safari.png'; // Replace with your image path
import desktopInstall from '../assets/desktop-chrome.png'; // Replace with your image path
import { Helmet } from 'react-helmet';

const InstallApp = () => {
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Install PWA User Guide- Liga SJKC Kuala Muda</title>
        <meta name="description" content="Install PWA user guide for Liga SJKC Kuala Muda." />
        <meta property="og:title" content="Install PWA user guide - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Install PWA user guide for Liga SJKC Kuala Muda." />
        <meta property="og:url" content="https://www.ligasjkc.com/installapp" />
      </Helmet>
    <CssBaseline />
      <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to="/">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          Install App
          </Typography>
        </Toolbar>
      </AppBar> 
    <Container>
      <Typography variant="h6" gutterBottom>
        Installing App Guide
      </Typography>
 
       
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="100%"
              image={chromeInstall}
              alt="Open the PWA in Chrome"
            />
            <CardContent>
              <Box sx={{ mt: 4, mb: 4 }}>
 

                <Typography variant="h6" component="h2" gutterBottom>
                  Android Chrome
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Open the PWA in Chrome:</strong> Visit the website you want to install that supports PWA.
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Open the Menu:</strong> Tap on the three vertical dots in the upper-right corner of the browser to open the menu.
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Install the PWA:</strong>
                  <ul>
                    <li>Look for an option that says "Install" or "Add to Home screen."</li>
                    <li>Tap on it. A prompt will appear confirming the installation.</li>
                    <li>Tap "Install" or "Add" to confirm.</li>
                  </ul>
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Access the PWA:</strong> The PWA will be added to your home screen, and you can access it just like a regular app.
                </Typography>

                {/* <Typography variant="h5" component="h2" gutterBottom>
                  Mozilla Firefox
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Open the PWA in Firefox:</strong> Visit the website you want to install that supports PWA.
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Open the Menu:</strong> Tap on the three vertical dots in the upper-right corner to open the menu.
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Install the PWA:</strong>
                  <ul>
                    <li>Scroll down and tap on "Install" or "Add to Home screen."</li>
                    <li>A prompt will appear, asking for confirmation.</li>
                    <li>Tap "Add" to confirm.</li>
                  </ul>
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Access the PWA:</strong> The PWA will be added to your home screen for easy access.
                </Typography> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="100%"
              image={safariInstall}
              alt="Install the PWA"
            />
            <CardContent>
              
            <Box sx={{ mt: 4, mb: 4 }}>
 

              <Typography variant="h6" component="h2" gutterBottom>
                iOS Safari
              </Typography>

              <Typography variant="body1" paragraph>
                <strong>Open the PWA in Safari:</strong> Visit the website you want to install that supports PWA.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Open the Share Sheet:</strong> Tap on the Share icon (a square with an upward arrow) at the bottom of the screen.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Add to Home Screen:</strong>
                <ul>
                  <li>Scroll down in the Share Sheet and tap on "Add to Home Screen."</li>
                  <li>You will see a preview of the app icon and can edit the name if desired.</li>
                  <li>Tap "Add" in the upper-right corner.</li>
                </ul>
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Access the PWA:</strong> The PWA will appear on your home screen and function like a regular app.
              </Typography>
            </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardMedia
              component="img"
              height="100%"
              image={desktopInstall}
              alt="Open the Menu"
            />
            <CardContent>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Desktop Chrome:
                </Typography>
 
           
                <Typography variant="body1" paragraph>
                <strong>Open the Website:</strong> Visit the website that you want to install as a PWA. Make sure the site supports PWA installation.
                </Typography>

             
                <Typography variant="body1" paragraph>
                <strong>Look for the Install Icon:</strong> In the right side of the address bar, you might see an icon that looks like a computer with a down arrow (this is the PWA installation icon). If you see this icon, click on it.
                </Typography>
                <Typography variant="body1" paragraph>
                  If you don't see the icon, click on the three vertical dots (menu) in the top-right corner of Chrome.
                </Typography>
 
                <Typography variant="body1" paragraph>
                <strong>Install the PWA:</strong>  If you used the PWA installation icon, just click "Install" when prompted.
                </Typography>
                <Typography variant="body1" paragraph>
                  If you used the menu, look for an option that says "Install [App Name]" and click on it.
                </Typography>
 
                <Typography variant="body1" paragraph>
                <strong>Access the PWA:</strong> Once installed, the PWA will open in a standalone window and behave like a native desktop application.
                </Typography>
                <Typography variant="body1" paragraph>
                  You can find the app in your Start menu (Windows) or Applications folder (Mac) and launch it directly from there.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
   
    </Container>
    </>
  );
};


export default InstallApp;
