import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Card, CardContent } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
 
const banners = [
  { id: 1, label: 'Longwan', icon: require('../assets/sponsors/longwan.png') },
  { id: 2, label: 'BKC', icon: require('../assets/sponsors/bkc.jpg') },
  { id: 3, label: 'Justbread', icon: require('../assets/sponsors/justbread.jpg')},
  { id: 4, label: 'GV', icon: require('../assets/sponsors/gv.jpg') },
  { id: 5, label: 'Respack', icon: require('../assets/sponsors/respack.jpg') },
 // { id: 6, label: 'KG Optometry', icon: require('../assets/sponsors/kgoptometry.jpg')},
 // { id: 7, label: 'Super Nice', icon: require('../assets/sponsors/supernice.jpg') },
  //{ id: 8, label: 'Well Point', icon: require('../assets/sponsors/wellpoint.jpg')},
  // Add more banners as needed
];

const Sponsor = () => {
/*   const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  }; */

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2, pb: 10 }}> {/* Add padding to the bottom */}
   
      
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <Typography variant="h6">Sponsors</Typography>
        <Typography variant="body2" color="primary">View all</Typography>
      </Box>
      <Slider {...settings}>
        {banners.map((banner) => (
          <Card key={banner.id}  >
            <CardContent>
             {/*  <Box display="flex" justifyContent="center" >
                <img src={banner.icon} alt={banner.label} style={{ width: '80px', height: '80px', maxWidth: '100%' }} />
              </Box> 
              <Typography variant="subtitle2" align="center">{banner.label}</Typography>*/}
               <LogoWrapper className="flexCenter">
          <ImgStyle src={banner.icon} alt={banner.label} />
        </LogoWrapper>
            </CardContent>
          </Card>
        ))}
      </Slider>
     
      
      
    </Box>
  );
};

const LogoWrapper = styled.div`
  width: 100%;
  height: 200px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;

export default Sponsor;
