import React, { useState } from 'react';
import { IconButton,CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';
import TableList from './TableList';
import { Link, useParams } from 'react-router-dom'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function TableHolder() {
    const { group } = useParams();
    const { name } = useParams();
  return (
    <>
             <CssBaseline />
      <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to="/tables">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          {name}
          </Typography>
        </Toolbar>
      </AppBar> 
 
        <TableList group={group} type="all" />
     
    </>
  );
}

export default TableHolder;
