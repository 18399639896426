import React, { useState, useEffect } from 'react';
import { Chip,Box, Card, CardContent, Typography, Avatar, Container, CircularProgress } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

// Sample data for matches
/* const matches = [
    { 
        team1: 'Team A', 
        team2: 'Team B', 
        schedule: '2024-08-15 18:00', 
        pitch: 'Pitch 1', 
        logo1: require(`../assets/teams/smb.png`),  // URL to team1 logo
        logo2: require(`../assets/teams/sinkwang.jpg`)  // URL to team2 logo
    },
    { 
        team1: 'Team C', 
        team2: 'Team D', 
        schedule: '2024-08-16 20:00', 
        pitch: 'Pitch 2', 
        logo1: 'https://example.com/logo3.png', 
        logo2: 'https://example.com/logo4.png' 
    },
    { 
        team1: 'Team E', 
        team2: 'Team F', 
        schedule: '2024-08-17 19:00', 
        pitch: 'Pitch 3', 
        logo1: 'https://example.com/logo5.png', 
        logo2: 'https://example.com/logo6.png' 
    },
    { 
        team1: 'Team G', 
        team2: 'Team H', 
        schedule: '2024-08-18 21:00', 
        pitch: 'Pitch 4', 
        logo1: 'https://example.com/logo7.png', 
        logo2: 'https://example.com/logo8.png' 
    },
    { 
        team1: 'Team I', 
        team2: 'Team J', 
        schedule: '2024-08-19 17:00', 
        pitch: 'Pitch 5', 
        logo1: 'https://example.com/logo9.png', 
        logo2: 'https://example.com/logo10.png' 
    },
]; */

 

const MatchUpcoming = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const fetchData = async () => {
      setLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/match/recent`);
      const result = await response.json();
      setMatches(result);
      setLoading(false);
      //console.log(response);
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    if (loading) {
      return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Container>
      );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Box sx={{ maxWidth: 500, margin: '0 auto', padding: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1}>
                <Typography variant="h6"> </Typography>
                <Link to="/matches" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2" color="primary">View all</Typography>
                </Link>
            </Box>
            <Slider {...settings}>
                {matches.map((item, index) => (
                    <Card 
                        key={index} 
                        sx={{ 
                            minHeight: 150, 
                            background: 'linear-gradient(to left, #61c7e3, #d368d3)', 
                            borderRadius: '16px', 
                            boxShadow: 3, 
                            padding: 1 
                        }}
                    >
                              
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Box display="flex" alignItems="center">
                                    <Avatar src={require(`../assets/teams/${item.logo1}`)} alt={`${item.teamName1} logo`} sx={{ marginRight: 1 }} />
                                    <Typography variant="subtitle1">{item.teamName1}</Typography>
                                </Box>
                                <Typography variant="body1">vs</Typography> 
                                {/* <Chip label="vs" color="primary" size="small" /> */}
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle1">{item.teamName2}</Typography>
                                    <Avatar src={require(`../assets/teams/${item.logo2}`)} alt={`${item.teamName2} logo`} sx={{ marginLeft: 1 }} />
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                <Box display="flex" alignItems="center">
                                    {/* <Typography variant="subtitle1">{item.score1}</Typography> */}
                                    <Chip label={item.score1} color="secondary" size="medium" />
                                </Box>
                                {/* <Typography variant="body1">vs</Typography> */}
                                <Chip label={item.potm} color={item.color} size="small" />  
                                <Box display="flex" alignItems="center">
                                    {/* <Typography variant="subtitle1">{item.score2}</Typography> */}
                                    <Chip label={item.score2} color="info" size="medium" />
                                </Box>
                            </Box>

                            {/* Center-aligning date, pitch, and group */}
                            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                <Typography variant="body2">
                                    {item.date}
                                </Typography>
                                <Typography variant="body2">
                                    {item.pitch}
                                </Typography>
                                <Chip label={item.code} color={item.color} size="small" />
                            </Box>
                        </CardContent>
                        {/* <Link to={`/matches/${item.id}/${item.team1}/${item.team2}`} style={{ textDecoration: 'none', color: 'inherit' }}></Link> */}
                    </Card>
                ))}
            </Slider>
        </Box>
    );
}

export default MatchUpcoming;
