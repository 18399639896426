import React from 'react';
import TeamGroup from './TeamGroup';
import { CssBaseline, Container } from '@mui/material';
import { Helmet } from 'react-helmet';

const Teams = () => {
  const teamSchema = {
    "@context": "https://schema.org/",
    "@type": "SportsTeam",
    "name": "Liga SJKC Teams",
    "logo": "https://example.com/logo.jpg",
    "url": "https://ligasjkc.com/teams",
    "description": "Discover all the teams participating in the league.",
    "sport": "Soccer"
  };
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
        <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Teams - Liga SJKC Kuala Muda</title>
        <meta name="description" content="Discover all the teams participating in the league." />
        <meta property="og:title" content="Teams - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Discover all the teams participating in the league." />
        <meta property="og:url" content="https://www.ligasjkc.com/teams" />
        <script type="application/ld+json">
          {JSON.stringify(teamSchema)}
        </script>
      </Helmet>
      <CssBaseline />
      <Container>
        <TeamGroup />
      </Container>
    </>
  );
};

export default Teams;
