import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton,Tabs, Tab, Box, Paper} from '@mui/material';
import { Link, useParams  } from 'react-router-dom';
import MatchGoal from './MatchGoal';
import MatchAssist from './MatchAssist';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MatchSave from './MatchSave';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function MatchTab() {
  const { id } = useParams();
   const { team1 } = useParams();
  const { team2 } = useParams(); 
  const { start } = useParams();
   const { end } = useParams();
  const { week } = useParams(); 
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
   
  return (
    
 <>
        <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} 
          to={`/matches/${start}/${end}/${week}`}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          {team1} / {team2}
          </Typography>
        </Toolbar>
      </AppBar> 
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Match Tabs"
          variant="fullWidth"
            indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Goals" />
          <Tab label="Assists" />
          <Tab label="Saves" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
         
        <MatchGoal id={id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        
        <MatchAssist id={id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        
        <MatchSave id={id} />
      </TabPanel>
      </>

  );
}

export default MatchTab;
