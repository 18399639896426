import React, { useState } from 'react';
import { CssBaseline, Container } from '@mui/material';
import TableGroup from './TableGroup';
import { Helmet } from 'react-helmet';
 
function Tables() {
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>League Rankings and Standings - Liga SJKC Kuala Muda</title>
        <meta name="description" content="Check out the latest team rankings and standings in the league. See how your favorite team ranks after the latest matches." />
        <meta property="og:title" content="League Rankings and Standings - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Check out the latest team rankings and standings in the league. See how your favorite team ranks after the latest matches." />
        <meta property="og:url" content="https://www.ligasjkc.com/tables" />
        <meta name="keywords" content="league rankings, team standings, soccer tables, football rankings, match results" />
      </Helmet>
      <CssBaseline />
      <Container>
        <TableGroup />
 
      </Container>
    </>
  );
}

export default Tables;
