import React, { useState } from 'react';
import { IconButton,CssBaseline, AppBar, Toolbar, Typography, Tabs, Tab, Box, Paper} from '@mui/material';
import ScoreGoal from './ScoreGoal';
import ScoreAssist from './ScoreAssist';
import { Link, useParams } from 'react-router-dom'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScoreOwn from './ScoreOwn';
import ScoreSave from './ScoreSave';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ScoreTab() {
    const { group } = useParams();
    const { name } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    
 <>
 <CssBaseline />
      <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to="/scores">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          {name}
          </Typography>
        </Toolbar>
      </AppBar> 
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scorers and assists tabs"
          variant="fullWidth"
            indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Top Goals" />
          <Tab label="Top Assists" />
          <Tab label="Top Saves" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
       
        <ScoreGoal group={group} />
      </TabPanel>
      <TabPanel value={value} index={1}>
       
        <ScoreAssist group={group} />
      </TabPanel>
      <TabPanel value={value} index={2}>
       
        <ScoreSave group={group} />
      </TabPanel>
      </>

  );
}

export default ScoreTab;
