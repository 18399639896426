import React, { useState, useEffect } from 'react';
import { CssBaseline, AppBar, Toolbar, Typography, List, ListItem, ListItemText, CircularProgress, Container, Fab, Avatar, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TeamList = ({_gid, _gname}) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/team/group/${_gid}`);
    const result = await response.json();
    setTeams(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

   if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  } 

  return (
   <>
   <CssBaseline /> 
      <Container> 
      <List>
        {teams.map((team) => (
          <ListItem button component={Link} to={`/teams/${_gname}/${team.name}/${_gid}/${team.id}`} key={team.id} divider>
            <Avatar src={require(`../assets/teams/${team.logo}`)}   alt={team.name} style={{ marginRight: '16px' }} /> 
            <ListItemText primary={team.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="details">
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          
        ))}
      </List>
      </Container>
      {/* <Fab
        color="error"
        aria-label="refresh"
        style={{
          position: 'fixed',
          bottom: 64,
          right: 16,
        }}
        onClick={fetchData}
      >
        <RefreshIcon />
      </Fab> */}
      </>
  );
};

export default TeamList;
