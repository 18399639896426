import React from 'react';
import { format, addDays, differenceInDays } from 'date-fns';
import { List, ListItem, ListItemText, Box, ListItemSecondaryAction, IconButton  } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const MatchWeek = ({ dateStart, dateEnd, handleDateRangeClick }) => {
  const startDate = new Date(dateStart);
  const endDate = new Date(dateEnd);
  const dayDifference = differenceInDays(endDate, startDate);

  const weekRanges = [];
  for (let i = 0; i <= dayDifference; i += 7) {
    const weekStart = addDays(startDate, i);
    const weekEnd = addDays(weekStart, 6);
    const weekNumber = Math.floor(i / 7) + 1; // Calculate week number
    weekRanges.push({
      start: formatDate(weekStart),
      end: formatDate(weekEnd),
      weekNumber: weekNumber
    });
   /*  weekRanges.push({
      start: format(weekStart, 'yyyy-MM-dd'),
      end: format(weekEnd, 'yyyy-MM-dd'),
      weekNumber: weekNumber
    }); */
  }

  return (
    <Box sx={{ pb: 7 }}> {/* Adjust padding-bottom as needed */}
    <List>
      {weekRanges.map((range, index) => (
        <ListItem button component={Link} to={`/matches/${range.start}/${range.end}/${range.weekNumber}`} key={index} onClick={() => handleDateRangeClick(range.start, range.end)} divider>
          <ListItemText primary={`Week ${range.weekNumber} `}
          secondary={`${range.start} - ${range.end}`} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="details">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
    </Box>
  );
};

export default MatchWeek;
