import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Container, Paper, List, ListItem, ListItemText, CircularProgress, Fab} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
 
const TeamPlayer = ({ id }) => {
 
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);


  
  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/player/team/${id}`);
    //console.log(`${apiUrl}/player/team/${id}`);
    const result = await response.json();
    if (Array.isArray(result)) {
      setPlayers(result);
    } else {
      console.error('API response is not an array:', result);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchData();
  }, [id]);  // Add id as a dependency to refetch data when id changes
  
  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    
 <>
       
       <List>
          {players.map((player, index) => (
          <React.Fragment key={index}>
            <ListItem key={index} sx={{ justifyContent: 'space-between' }} divider>
              <Box sx={{ flexGrow: 1 }}>
                <ListItemText
                  primary={`${player.name}`} 
                />
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <ListItemText
                  primary={`${player.number}`}
                  secondary="Shirt"
                />
              </Box>
            </ListItem>
          </React.Fragment>
          ))}
        </List>
        {/* <Fab
          color="error"
          aria-label="refresh"
          style={{
            position: 'fixed',
            bottom: 64,
            right: 16,
          }}
          onClick={fetchData}
        >
          <RefreshIcon />
        </Fab> */}

     </>

  );
}

export default TeamPlayer;
