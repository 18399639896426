import React, { useState } from 'react';
import { Chip, Box, Typography,  CssBaseline, Container, Tabs, Tab, Paper } from '@mui/material';
import Sponsor from './Sponsor';
import MatchUpcoming from './MatchUpcoming';
import MatchRecent from './MatchRecent';
import TableList from './TableList';
import { Link } from 'react-router-dom';
import SponsorSlider from './SponsorSlider';

import { Helmet } from 'react-helmet';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
     
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Home = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Home - Liga SJKC Kuala Muda</title>
        <meta name="description" content="Check out the upcoming matches, recent matches result and top 3 standings in league table." />
        <meta property="og:title" content="Home - Liga SJKC Kuala Muda" />
        <meta property="og:description" content="Check out the upcoming matches, recent matches result and top 3 standings in league table." />
        <meta property="og:url" content="https://www.ligasjkc.com" />
      </Helmet>
    <CssBaseline />

{/*       <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                <Typography variant="h6">2024 Season 2</Typography> 
                <Link to="/tables" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2" color="primary">View all</Typography>
                </Link>
            </Box> */}
            <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="recent and upcoming tabs"
          variant="fullWidth"
        >
          <Tab label="Recent Matches" />
          <Tab label="Upcoming Matches" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
 
 
      <MatchRecent /> 

      </TabPanel>
      <TabPanel value={value} index={1}>
     
     
      <MatchUpcoming /> 
      </TabPanel>
      <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2, pb: 10 }}> {/* Add padding to the bottom */}
   
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                <Typography variant="h6"> 
                 <Chip label="U12 Boy" color="secondary" size="small" /> <Chip label="Top 3" color="error" size="small" /></Typography> 
                <Link to="/tables" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2" color="primary">View all</Typography>
                </Link>
            </Box> 
      <TableList group="1" type="top3"  />  
        {/* <Sponsor /> 
        <SponsorSlider />*/}
        </Box> 
    </>
  );
};

export default Home;
