import React, { useState } from 'react';
import { IconButton,CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';
import TeamList from './TeamList';
import { Link, useParams } from 'react-router-dom'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function TeamHolder() {
    const { gid } = useParams(); // Group Id
    const { gname } = useParams(); // Group Name
  return (
    <>
             <CssBaseline />
      <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to="/teams">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          {gname}
          </Typography>
        </Toolbar>
      </AppBar> 
 
        <TeamList _gid={gid} _gname={gname} />
     
    </>
  );
}

export default TeamHolder;
