import React, { useState, useEffect } from 'react';
import { IconButton, Avatar, List, ListItem, ListItemText, Modal, Container, Typography, Box, CircularProgress, Fab, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
const MatchList = ({ start, end, week }) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/match/${start}/${end}`);
    const result = await response.json();
    setMatches(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <List>
        {matches.map((item, index) => (
          <ListItem 
            button 
            key={index} 
            component={Link} 
            to={`/matches/${item.id}/${item.teamName1}/${item.teamName2}/${start}/${end}/${week}`} 
            divider
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Box display="flex" alignItems="center">
                    <Avatar 
                      src={require(`../assets/teams/${item.logo1}`)} 
                      alt={item.teamName1} 
                      style={{ marginRight: '8px' }} 
                    />
                    <Typography variant="body2">
                      {item.teamName1}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Avatar 
                      src={require(`../assets/teams/${item.logo2}`)} 
                      alt={item.teamName2} 
                      style={{ marginRight: '8px' }} 
                    />
                    <Typography variant="body2">
                      {item.teamName2}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="body2" align="right">
                    {item.score1}
                  </Typography>
                  <Typography variant="body2" align="right">
                    {item.score2}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="caption" color="textSecondary">
                  
                {item.date}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                 {item.potm} <MilitaryTechOutlinedIcon />
                </Typography>
              </Box>
              <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                <Chip label={item.code} color={item.color} size="small" />
                {item.status === 1 && (
                  <Chip label="Completed" color="success" size="small" />
                )}
                {item.status === 2 && (
                  <Chip label="Postponed" color="error" size="small" />
                )}
                {item.status === 0 && (
                  <Chip label="Upcoming" color="info" size="small" />
                )}
                <Typography variant="caption" color="textSecondary">
                  {"Goal/Assist"} <SportsSoccerIcon />  
                </Typography>
              </Box>

              
            </Box>
          </ListItem>
        ))}
      </List>
      {/* <Fab
        color="error"
        aria-label="refresh"
        style={{
          position: 'fixed',
          bottom: 64,
          right: 16,
        }}
        onClick={fetchData}
      >
        <RefreshIcon />
      </Fab> */}
    </>
  );
};

export default MatchList;
