// src/components/LeagueTable.js
import React, { useEffect, useState } from 'react';
import {Avatar, CircularProgress, Fab,Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link, useParams } from 'react-router-dom'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TableList = ({group, type}) => {
  //const { group } = useParams();
  //const { name } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    //console.log(apiUrl);
    let endpoint;
    if (type == "top3"){
      endpoint = `${apiUrl}/league/${type}/${group}`;
    }else{
      endpoint = `${apiUrl}/league/group/${group}`;
    } 
    //console.log(endpoint);
    const response = await fetch(endpoint);
    const result = await response.json();
    setData(result);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
   if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  } 

  return (
    <>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
        <TableRow>
              <TableCell style={{ fontWeight: 'bold', background: 'lightyellow' }}>Team</TableCell>
              <TableCell style={{ fontWeight: 'bold', background: 'lightyellow' }}>MP</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: 'lightyellow' }}>W</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: 'lightyellow' }}>D</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: 'lightyellow' }}>L</TableCell>
              {/* <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>GF</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>GA</TableCell> */}
              <TableCell style={{ fontWeight: 'bold', backgroundColor: 'lightyellow' }}>GD</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#FEDA75' }}>Pts</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell><Avatar src={require(`../assets/teams/${item.logo}`)}   alt={item.team} style={{ marginRight: '16px'}} /> {item.team}</TableCell>
              <TableCell>{item.mp}</TableCell>
              <TableCell>{item.win}</TableCell>
              <TableCell>{item.draw}</TableCell>
              <TableCell>{item.lose}</TableCell>
              {/* <TableCell>{item.gf}</TableCell>
              <TableCell>{item.ga}</TableCell> */}
              <TableCell>{item.gd}</TableCell>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#FEDA75' }}>{item.pts}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      {/* <Fab
        color="error"
        aria-label="refresh"
        style={{
          position: 'fixed',
          bottom: 64,
          right: 16,
        }}
        onClick={fetchData}
      >
        <RefreshIcon />
      </Fab> */}
    </>
  );
};

export default TableList;
