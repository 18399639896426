import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton,Tabs, Tab, Box, Paper} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams  } from 'react-router-dom';
import TeamPlayer from './TeamPlayer';
import TeamMatch from './TeamMatch';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TeamTab() {
  const { id } = useParams(); //Team Id
  const { name } = useParams(); //Team Name
  const { gid } = useParams(); // Group Id
  const { gname } = useParams(); // Group Name
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
 <>
       <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to={`/teams/${gname}/${gid}`}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
             {name}
          </Typography>
        </Toolbar>
      </AppBar> 
        
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scorers and assists tabs"
          variant="fullWidth"
        >
          <Tab label="Players" />
          <Tab label="Matches" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <TeamPlayer id={id} />

      </TabPanel>
      <TabPanel value={value} index={1}>
        <TeamMatch id={id} />
      </TabPanel>
      </>
  );
}

export default TeamTab;
