import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useBackButtonHandler() {
  const [backPressed, setBackPressed] = useState(false);
  const navigate = useNavigate(); // Add this line to use the navigate hook
  useEffect(() => {
    const handleBackButton = (event) => {
      if (backPressed) {
        //window.close(); // This may not work on all browsers
        navigate('/'); // Redirect to home route
        //alert('Press again back to Home for exit app');
      } else {
        setBackPressed(true);
        //alert('Press back again to exit');
        setTimeout(() => {
          setBackPressed(false);
        }, 2000); // 2 seconds to reset the back pressed state
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [backPressed]);
  }

export default useBackButtonHandler;
