import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, CircularProgress, Container, Fab, Avatar, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TableGroup = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/group`);
    const result = await response.json();
    setTeams(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

   if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  } 

  return (
   <>
      <List>
        {teams.map((index) => (
          <ListItem button component={Link} to={`/tables/${index.name}/${index.id}`} key={index.id} divider>
            <ListItemText primary={index.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="details">
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          
        ))}
      </List>
      {/* <Fab
        color="error"
        aria-label="refresh"
        style={{
          position: 'fixed',
          bottom: 64,
          right: 16,
        }}
        onClick={fetchData}
      >
        <RefreshIcon />
      </Fab> */}
      </>
  );
};

export default TableGroup;
