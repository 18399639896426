import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Container, Paper, List, ListItem, ListItemText, CircularProgress, Fab} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
 
function MatchSave({id}) {
 
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);

 
  
  const fetchData = async () => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/save/match/`+id);
    const result = await response.json();
    setGoals(result);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    
 <>
       
        <List>
          {goals.map((item, index) => (
            <React.Fragment key={index}>
            <ListItem key={index} sx={{ justifyContent: 'space-between' }} divider>
              <Box sx={{ flexGrow: 1 }}>
                <ListItemText
                  primary={`${item.playerName} (${item.playerNumber})`}
                  secondary={`${item.teamName}`}
                />
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <ListItemText
                  primary={`${item.total}`}
                  secondary="in Total"
                />
              </Box>
            </ListItem> 
          </React.Fragment>
          ))}
        </List>
        {/* <Fab
          color="error"
          aria-label="refresh"
          style={{
            position: 'fixed',
            bottom: 64,
            right: 16,
          }}
          onClick={fetchData}
        >
          <RefreshIcon />
        </Fab> */}

     </>

  );
}

export default MatchSave;
