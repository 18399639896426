import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { CssBaseline,AppBar, Toolbar, IconButton, Container, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MatchList from './MatchList';
/* const matches = [
  {
    id: 1,
    date: '2024-07-28',
    teams: 'Team A vs Team B',
    scorer: 'Player 1',
    details: 'Detailed match report for Team A vs Team B'
  },
  {
    id: 2,
    date: '2024-07-29',
    teams: 'Team C vs Team D',
    scorer: 'Player 2',
    details: 'Detailed match report for Team C vs Team D'
  }
]; */

const MatchDetail = () => {
  const { date1 } = useParams();
  const { date2 } = useParams();
  const { week } = useParams();
/*   const match = matches.find(match => match.id === parseInt(id));

  if (!match) {
    return <Typography variant="h6">Match not found</Typography>;
  } */
 

  return (
    <>
      <CssBaseline />
      <AppBar position="static" style={{ 
          background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
        }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" component={Link} to="/matches">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
         
          Week {week} ({date1} - {date2})
          </Typography>
        </Toolbar>
      </AppBar> 
 
      <MatchList start={date1} end={date2} week={week} />
  
      {/* <Typography variant="body1" gutterBottom>
        Date: {match.date}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Scorer: {match.scorer}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Details: {match.details}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/matches" style={{ marginTop: '20px' }}>
        Back to Match List
      </Button> */}
    </>
  );
};

export default MatchDetail;
